import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./slices/auth";
import modalReducer from "./slices/modal";
import loaderReducer from "./slices/loader";
import bookingReducer from "./slices/booking";
import { navigationReducer } from "./slices/sidebar/navigationSlice";
import formLoaderReducer from "./slices/formLoader/formLoaderSlice";
import franchiseReducer from "./slices/franchise/franchiseSlice";
import employeeReducer from "./slices/employee/employeeSlice";
import addBookingReducer from "./slices/addBooking";
import supportTicketReducer from "./slices/supportTicket";
import budgetReducer from "./slices/Budget";
import addChartBookingCountReducer from "./slices/dashboardChart";

const appReducer = combineReducers({
  form: formReducer,

  auth: authReducer,
  employee: employeeReducer,
  franchise: franchiseReducer,
  modal: modalReducer,
  loader: loaderReducer,
  booking: bookingReducer,
  chartBooking: addChartBookingCountReducer,
  addBooking: addBookingReducer,
  formLoader: formLoaderReducer,
  navigation: navigationReducer,
  supportTicket: supportTicketReducer,
  budget: budgetReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
