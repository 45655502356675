import { lazy } from "react";

const Login = lazy(() => import("pages/Login"));
const PasswordOTP = lazy(() => import("pages/PasswordOTP"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

const Profile = lazy(() => import("pages/Profile"));
const Dashboard = lazy(() => import("pages/Dashboard"));

const AddBooking = lazy(() => import("pages/AddBooking"));
const SearchFleet = lazy(() => import("pages/AddBooking/SearchFleet"));
const UpdateBooking = lazy(() => import("pages/UpdateBooking"));

const Bookings = lazy(() => import("pages/Bookings"));
const BookingDetails = lazy(() => import("pages/BookingDetails"));

const Employees = lazy(() => import("pages/Employees"));
const AddEmployee = lazy(() => import("pages/AddEmployee"));
const EmployeeDetails = lazy(() => import("pages/EmployeeDetails"));

const Franchises = lazy(() => import("pages/Franchises"));
const AddFranchise = lazy(() => import("pages/AddFranchise"));
const FranchiseDetails = lazy(() => import("pages/FranchiseDetails"));

const SupportTickets = lazy(() => import("pages/SupportTickets"));
const AddSupportTicket = lazy(() => import("pages/AddSupportTicket"));
const SupportTicketDetails = lazy(() => import("pages/SupportTicketDetails"));

const Budgets = lazy(() => import("pages/Budgets"));
const AddBudget = lazy(() => import("pages/AddBudget"));
const BudgetDetails = lazy(() => import("pages/BudgetDetails"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [
  { path: "/", element: <Login /> },
  { path: "/password-otp", element: <PasswordOTP /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
];

export const private_routes: IRoute[] = [
  { path: "/profile", element: <Profile /> },
  { path: "/dashboard", element: <Dashboard /> },

  { path: "/search-fleet", element: <SearchFleet /> },
  { path: "/add-booking", element: <AddBooking /> },
  { path: "/update-booking/:id", element: <UpdateBooking /> },

  { path: "/booking", element: <Bookings type={"confirm"} /> },
  { path: "/ongoing-bookings", element: <Bookings type={"inprogress"} /> },
  { path: "/past-bookings", element: <Bookings type="finish" /> },
  { path: "/booking-details/:id", element: <BookingDetails /> },

  { path: "/franchises", element: <Franchises /> },
  { path: "/add-franchise", element: <AddFranchise /> },
  { path: "/update-franchise/:id", element: <AddFranchise /> },
  { path: "/franchise-details/:id", element: <FranchiseDetails /> },

  { path: "/employees", element: <Employees /> },
  { path: "/add-employee", element: <AddEmployee /> },
  { path: "/update-employee/:id", element: <AddEmployee /> },
  { path: "/employee-details/:id", element: <EmployeeDetails /> },

  { path: "/support", element: <SupportTickets /> },
  { path: "/add-ticket", element: <AddSupportTicket /> },
  { path: "/ticket-details/:id", element: <SupportTicketDetails /> },

  { path: "/budgets", element: <Budgets /> },
  { path: "/add-budget", element: <AddBudget /> },
  { path: "/update-budget/:id", element: <AddBudget /> },
  { path: "/budget-details/:id", element: <BudgetDetails /> },
];
