import { config } from "config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupportTicketState } from ".";

const default_page_size = config.PAGE_SIZE;
const initialState: SupportTicketState = {
  tickets: [],
  ticket: null,
  reasons: [],
  loading: true,
  current_filters: {},
  filters: { page: 1, pageSize: default_page_size },
  refresh: 0,
  refreshLoader: false,
  totalCount: 0,
};

export const supportTicketSlice = createSlice({
  name: "supportTicket",
  initialState,
  reducers: {
    // clear: (state) => {
    //   state.carOptions = [];
    // },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addTicket: (state, action) => {
      state.tickets.unshift(action.payload);
    },
    updateTicket: (state, action) => {
      const { id, car } = action.payload;
      state.tickets.every(({ _id }, i) => {
        if (id === _id) {
          state.tickets[i] = car;
          return false;
        }
        return true;
      });
    },
    deleteTicketById: (state, action) => {
      const id = action.payload;
      state.tickets.every(({ _id }, i) => {
        if (id === _id) {
          state.totalCount -= 1;
          state.tickets.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setTicket: (state, action) => {
      state.ticket = action.payload;
    },
    setTickets: (state, action: PayloadAction<any>) => {
      const { tickets } = action.payload;
      state.tickets = tickets;
    },
    setReasons: (state, action) => {
      state.reasons = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<any>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
    },
    setPage: (state, action: PayloadAction<any>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const supportTicketReducer = supportTicketSlice.reducer;

export const supportTicketActions = supportTicketSlice.actions;
export default supportTicketReducer;
