import { DashboardChartState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: DashboardChartState = {
  loading: false,
  fleetLoading: false,
  chartBookingCount: null,
  chartBookingCard: null,
};

export const addChartBookingCountSlice = createSlice({
  name: "addChartBookingCount",
  initialState,
  reducers: {
    clear: (state) => {
      state.fleetLoading = false;
      state.chartBookingCount = null;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFleetLoader: (state, action: PayloadAction<boolean>) => {
      state.fleetLoading = action.payload;
    },
    setChartBookingCount: (state, action) => {
      state.chartBookingCount = action.payload;
      const chartData = action.payload;
      state.chartBookingCard = [
        { daily: chartData.dailyBookings, yesterday: chartData.yesterdayBookings, monthly: chartData.monthlyBookings }
      ];
    },
  },
});

const addChartBookingCountReducer = addChartBookingCountSlice.reducer;

export const addChartBookingCountActions = addChartBookingCountSlice.actions;
export default addChartBookingCountReducer;
