import { config } from "config";
import { SetEmployeesPayload, EmployeeState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: EmployeeState = {
  count: 0,
  refresh: 0,
  employees: [],
  employeeOptions: [],
  employee: null,
  loading: true,
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    clear: (state) => {
      state.employeeOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addEmployee: (state, action) => {
      state.employees.unshift(action.payload);
    },
    updateEmployee: (state, action) => {
      const { id, employee } = action.payload;
      state.employees.every(({ _id }, i) => {
        if (id === _id) {
          state.employees[i] = employee;
          return false;
        }
        return true;
      });
    },
    deleteEmployeeById: (state, action) => {
      const id = action.payload;
      state.employees.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.employees.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
    },
    setEmployees: (state, action: PayloadAction<SetEmployeesPayload>) => {
      const { count, employees } = action.payload;
      let options: SelectOption[] = [];
      employees.forEach(({ _id, firstName, lastName }: any) => {
        const option = { value: _id, label: `${firstName} ${lastName}` };
        options.push(option);
      });
      state.count = count;
      state.employees = employees;
      state.employeeOptions = options;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const employeeReducer = employeeSlice.reducer;

export const employeeActions = employeeSlice.actions;
export default employeeReducer;
