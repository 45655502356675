import {
  UserMenuContainer,
  UserMenuIconButton,
  UserMenuInfo,
  UserMenuMenu,
  UserMenuMenuItem,
  UserMenuMenuItemWithSeparator,
  UserNameIconButton,
} from "./styled";
import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import { useNavigate } from "react-router-dom";
import { SettingsOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const UserMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <UserMenuContainer>
      <UserMenuIconButton onClick={handleClick}>
        <UserNameIconButton>
          {user?.user?.firstName?.charAt() + user?.user?.lastName?.charAt()}
        </UserNameIconButton>
        <SettingsOutlined />
      </UserMenuIconButton>
      <UserMenuMenu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <UserMenuInfo>
          <Typography fontSize={14} color={"text.secondary"}>
            <span style={{ textTransform: "capitalize" }}>
              {t(user?.user?.firstName) + " " + t(user?.user?.lastName)}
            </span>
            <br />
            {user?.user?.email}
          </Typography>
        </UserMenuInfo>
        <UserMenuMenuItem onClick={() => navigate("/profile")}>
          {t("profile")}
        </UserMenuMenuItem>
        <UserMenuMenuItem onClick={() => navigate("/budgets")}>
          {t("budgets")}
        </UserMenuMenuItem>
        <UserMenuMenuItemWithSeparator onClick={() => AuthService.logout()}>
          {t("logout")}
        </UserMenuMenuItemWithSeparator>
      </UserMenuMenu>
    </UserMenuContainer>
  );
};
