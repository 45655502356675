export const config = {
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.REACT_APP_ENV,
  APP_URL: `${process.env.REACT_APP_URL}`,
  API_URL: process.env.REACT_APP_API_URL,
  SOCKET_URL: `${process.env.REACT_APP_SOCKET_URL}`,
  LOCAL_API_URL: process.env.REACT_APP_LOCAL_API_URL,
  ONE_SIGNAL_APP_ID: `${process.env.REACT_APP_ONE_SIGNAL_APP_ID}`,
  SENTRY_PUBLIC_KEY: process.env.REACT_APP_SENTRY_PUBLIC_KEY,
  SENTRY_RELEASE_KEY: process.env.REACT_APP_SENTRY_RELEASE_KEY,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  PAGE_SIZE: Number(process.env.REACT_APP_PAGE_SIZE) || 10,
  MAX_KM_PER_HOUR: Number(process.env.REACT_APP_MAX_KM_PER_HOUR) || 0,
};
