import { lazy, useMemo } from "react";
import {
  AirportShuttleOutlined,
  FactCheckOutlined,
  AccountTreeOutlined,
  TimeToLeaveOutlined,
  SupportAgent,
  AddBoxOutlined,
  HubOutlined,
  BadgeOutlined,
  Support,
} from "@mui/icons-material";
import UpcomingOutlinedIcon from "@mui/icons-material/UpcomingOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { NavigationItemType } from "./components/navigation-item/types";
import { useTranslation } from "react-i18next";
// import BookingsTabs from "pages/Bookings/BookingsTabs";
const BookingsTabs = lazy(() => import("pages/Bookings/BookingsTabs"));

export const useNavigationItems = (): NavigationItemType[] => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        path: "/dashboard",
        label: t("dashboard"),
        icon: (props: any) => <DashboardOutlinedIcon {...props} />,
      },
      {
        path: "/booking",
        label: t("bookings"),
        icon: (props: any) => <SupportAgent {...props} />,
        items: [
          {
            label: t("upcoming"),
            icon: (props: any) => <UpcomingOutlinedIcon {...props} />,
            element: <BookingsTabs />,
          },
          {
            label: t("on-going"),
            icon: (props: any) => <AirportShuttleOutlined {...props} />,
            element: <BookingsTabs />,
          },
          {
            label: t("past"),
            icon: (props: any) => <FactCheckOutlined {...props} />,
            element: <BookingsTabs />,
          },
        ],
      },
      {
        path: "/franchises",
        label: t("franchises"),
        icon: (props: any) => <HubOutlined {...props} />,
      },
      {
        path: "/employees",
        label: t("employees"),
        icon: (props: any) => <BadgeOutlined {...props} />,
      },
      {
        path: "/support",
        label: t("support"),
        icon: (props: any) => <Support {...props} />,
      },
    ],
    []
  );
};
